import { createApp } from 'vue'
import App from './App.vue'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import router from './router'
import './index.css'


createApp(App)
    .use(router).mount('#app')
    .use(VuePlyr, {
        plyr: {}
    })
    .use(router).mount('#app')
    .mount('#app')
