<template>
  <body>
    <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css" rel="stylesheet">
    <div class="home bg-black flex items-start justify-center" style="height: 15vh;">
      <div class="p-4">
        <img src="../assets/icon.png" class="rounded-full w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32" alt="Description">
      </div>
    </div>

    <div class="bg-black flex flex-col items-center justify-center space-y-10 spacing">
      <h1 id="title" class="text-6xl font-bold text-white">MCU-IS</h1>
      <div class="w-1/3 mx-auto">
        <table class="w-full table-auto border-collapse border border-white text-white">
          <thead>
            <tr>
              <th class="w-1/2 px-4 py-2">Operating System</th>
              <th class="w-1/2 px-4 py-2">Support Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border border-white px-4 py-2">Windows</td>
              <td class="border border-white px-4 py-2">
                <i class="fas fa-check text-green-500"></i>
              </td>
            </tr>
            <tr>
              <td class="border border-white px-4 py-2">Linux</td>
              <td class="border border-white px-4 py-2 text-red-500">❌</td>
            </tr>
            <tr>
              <td class="border border-white px-4 py-2">Mac</td>
              <td class="border border-white px-4 py-2 text-red-500">❌</td>
            </tr>
          </tbody>
        </table>
      </div>
      <a href="http://mcu-is.arping.me/download/MCU-IS-setup.exe" download class="px-12 py-2 bg-blue-500 text-white rounded space-y-10">
        Download
      </a>
      <a style="color: white;" href="https://drive.google.com/file/d/1A-gIe3JKvtXXQZ6C2rXVAiOhldB1iYgR/view?usp=sharing" target="_blank">下載此檔案即代表同意此說明書</a>
    </div>
    <div class="bg-black flex flex-col items-center justify-center space-y-10 spacing">
      <div class="flex space-x-10">
        <div id="youtube-player"></div>
        <div id="youtube-player1"></div>
      </div>
    </div>
    <div class="bg-black flex justify-center p-4">
      <div class="flex items-center space-x-4">
        <h1 id="title" class="text-4xl font-bold text-white">選課系統</h1>
        <button class="white-button" v-if="!isContentVisible" @click="toggleContent">{{ buttonText }}</button>
      </div>
    </div>
    <div>
        <div v-if="isContentVisible">
          <div class="bg-black flex flex-col items-center justify-center space-y-10 p-4">
            <p class="text-white text-center">
              在學生資訊系統中，選課常常是一項繁瑣的工作，特別是當你需要一邊勾選空閒時間，一邊尋找合適的課程並手動調整優先順序。我們的專案團隊致力於解決這些不便。
            </p>
            <p class="text-white text-center">
              現在，我們重新設計了選課過程，讓你可以以更直觀的方式選擇課程節次。你不再需要逐個插入課程到時間表中，也不必手動調整優先程度。我們的新系統允許你簡單地滑動以設定優先程度，使操作變得更加方便快捷。
            </p>
            <p class="text-white text-center">
              更進一步，我們的系統能夠從學生資訊中自動獲取重要信息，例如你已修過的課程，還未選的通識課，甚至是體育課的上課時間。這意味著你可以更加輕鬆地規劃學習進程，無需浪費時間在繁瑣的查詢上。
            </p>
            <p class="text-white text-center">
              如要查詢課程評價，也能在選課的每一堂課上直接連接到銘傳金手指，以最快的方式獲取到該門課的評價。
            </p>
            <p class="text-white font-bold text-center">
              且我們會將選擇的課程自動導入學生資訊系統！
            </p>
          </div>

          <div class="bg-black flex flex-col items-center justify-center space-y-4 p-4">
            <div class="w-2/3 space-y-4 mx-auto"> 
              <img src="../assets/SC1.png" alt="Screenshot 1" class="w-full h-auto">
              <img src="../assets/SC3.png" alt="Screenshot 3" class="w-full h-auto">
              <img src="../assets/SC2.png" alt="Screenshot 2" class="w-full h-auto">  
            </div>
          </div>
          <button class="white-button" @click="toggleContent">{{ buttonText }}</button>
        </div>
    </div>
    
    <div class="bg-black flex justify-center p-4">
      <div class="flex items-center space-x-4">
        <h1 id="roll-call-title" class="text-4xl font-bold text-white">點名系統</h1>
        <button class="white-button" v-if="!isRollCallContentVisible" @click="toggleRollCallContent">{{ rollCallButtonText }}</button>
      </div>
    </div>

    <div>
      <div v-if="isRollCallContentVisible">
        <div class="bg-black flex flex-col items-center justify-center space-y-10 p-4">
          <p class="text-white text-center">
            考慮到有些同學會被教師指定點名，常常發生念錯同學名字的冏態，以及點名花太久時間的問題，因此我們團隊將唱名功能整合進學生點名系統，並且教師點名系統同樣有該功能，希望能大大改善點名效率！
          </p>
          <div class="bg-black flex flex-col items-center justify-center space-y-4 p-4">
            <div class="w-2/3 space-y-4 mx-auto"> 
              <img src="../assets/C1.jpeg" alt="Screenshot 1" class="w-full h-auto">
              <img src="../assets/C2.jpeg" alt="Screenshot 3" class="w-full h-auto">
              <img src="../assets/C3.jpeg" alt="Screenshot 2" class="w-full h-auto">  
            </div>
          </div>
        </div>
        <button class="white-button"  @click="toggleRollCallContent">{{ rollCallButtonText }}</button>
      </div>
    </div>

  </body>
</template>

<script>
export default {
  data() {
    return {
      isContentVisible: false,
      isRollCallContentVisible: false,
    };
  },
  computed: {
    buttonText() {
      return this.isContentVisible ? 'Hide' : 'ShowMore >>';
    },
    rollCallButtonText() {
      return this.isRollCallContentVisible ? 'Hide' : 'ShowMore >>'; // New computed property for roll call button text
    },
  },  
  mounted() {
    document.title = 'MCU-IS';
    window.onYouTubeIframeAPIReady = () => {
      this.createYouTubePlayer();
    };
    if (typeof YT === 'undefined' || !YT.Player) {
      let tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      let firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      this.createYouTubePlayer();
    };
      // document.getElementById('downloadAgreement').addEventListener('click', function(event) {
      //   event.preventDefault();
      //   window.location.href = this.getAttribute('href');
      // });
  },
  methods: {
    toggleRollCallContent() {
      this.isRollCallContentVisible = !this.isRollCallContentVisible;
    },
    toggleContent() {
      this.isContentVisible = !this.isContentVisible;
    },
    createYouTubePlayer() {
      this.player = new YT.Player('youtube-player', { 
        height: '360',
        width: '640',
        videoId: 'PoeyjIPAySw',
        events: {
          'onReady': this.onPlayerReady,
          'onStateChange': this.onPlayerStateChange
        }
      },
      this.player = new YT.Player('youtube-player1', { 
        height: '360',
        width: '640',
        videoId: 'OwFHiJMVGnw',
        events: {
          'onReady': this.onPlayerReady,
          'onStateChange': this.onPlayerStateChange
        }
      })
      );
    },
    onPlayerReady(event) {
    },
    onPlayerStateChange(event) {
    }
  }
};
</script>

<style>
  body {
    background-color: black;
  }
  .spacing {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  .white-button {
    color: white;
  }
</style>
